<template>
    <v-dialog v-model="isActive" width="550">
        <template>
            <v-card>
                <div
                    style="height: 5vh; background-color: #E5E4E2; display: flex; align-items: center; padding-left: 20px;">
                    <h5>Upload Your .Xlsx File</h5>
                </div>
                <div style="padding: 1.5rem;">
                    <div class="dropzone-container" @dragover="dragover" @dragleave="dragleave" @drop="drop">
                        <input type="file" name="file" id="fileInput" class="hidden-input" @change="onChange" ref="file"
       accept=".xlsx" />

                        <v-icon size="70" style="color: #E5E4E2;">
                            mdi-file-upload-outline
                        </v-icon>
                        <div class="text-center" v-if="isUploading">
                            <br />
                            <v-progress-circular :size="50" color="primary" indeterminate></v-progress-circular>
                            <div style="font-weight: bold; font-size: small;">Updating quarterly research data based on
                                the provided CSV file.</div>
                            <div style="color:#708090; font-weight: bold; font-size: x-small; text-align: center;">
                                Please wait...</div>
                        </div>
                        <label v-else for="fileInput" class="file-label">
                            <div v-if="isDragging" style="font-weight: bold; font-size: small;">Release to drop your
                                file here.</div>
                            <div v-else style="font-weight: bold; font-size: small;">Drag and Drop or <u>Click</u> to
                                upload the file.</div>
                            <div style="color:#E5E4E2; font-weight: bold; font-size: x-small; text-align: center;">
                                Supported Formats: .xlsx Max File Size: 25MB</div>
                        </label>
                    </div>
                </div>
                <!-- <DropFile/> -->
            </v-card>
        </template>
    </v-dialog>
</template>

<script>
import cityService from '../services/city.service';
export default {
    data() {
        return {
            isActive: false,
            isDragging: false,
            files: [],
            isUploading: false
        };
    },
    components: {
    },
    methods: {
        uploadFiles() {
            this.isUploading = true;
            const files = this.files;
            const formData = new FormData();
            files.forEach((file) => {
                formData.append("file", file);
            });
            try {
                cityService.UploadResearch(formData).then((res) => {
                    this.files = [];
                    this.isUploading = false;
                    if (res.status == 200) {
                        this.files = [];
                        let payload2 = {
                            snackBarText: `Research data updated!`,
                            snackBarColor: "success",
                        };
                        this.$store.dispatch("notify/setSnackbarMessage", payload2);
                        this.isActive = false;
                    } else {
                        let payload2 = {
                            snackBarText: `Seems to be an error!`,
                            snackBarColor: "error",
                        };
                        this.$store.dispatch("notify/setSnackbarMessage", payload2);
                    }
                });
            } catch (error) {
                let payload2 = {
                    snackBarText: `Having trouble uploading the file!`,
                    snackBarColor: "error",
                };
                this.$store.dispatch("notify/setSnackbarMessage", payload2);
            }
        },
        onChange() {
            const self = this;
            let incomingFiles = Array.from(this.$refs.file.files);
            const fileExist = self.files.some((r) =>
                incomingFiles.some(
                    (file) => file.name === r.name && file.size === r.size
                )
            );
            if (fileExist) {
                self.showMessage = true;
                alert("New upload contains files that already exist");
            } else {
                self.files.push(...incomingFiles);
                this.uploadFiles()
            }
        },
        dragover(e) {
            e.preventDefault();
            this.isDragging = true;
        },
        dragleave() {
            this.isDragging = false;
        },
        drop(e) {
            e.preventDefault();
            const droppedFiles = e.dataTransfer.files;
            this.files.push(...Array.from(droppedFiles));
            this.isDragging = false;
            this.onChange();
        }

    }
};
</script>
<style scoped>
.dropzone-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 25vh;
    border-radius: 5px;
    border: 2px dashed #35a7ff;
}

.hidden-input {
    opacity: 0;
    overflow: hidden;
    position: absolute;
    width: 1px;
    height: 1px;
}

.file-label {
    font-size: 20px;
    display: block;
    cursor: pointer;
}

.preview-container {
    display: flex;
    margin-top: 2rem;
}

.preview-card {
    display: flex;
    border: 1px solid #a2a2a2;
    padding: 5px;
    margin-left: 5px;
}

.preview-img {
    width: 50px;
    height: 50px;
    border-radius: 5px;
    border: 1px solid #a2a2a2;
    background-color: #a2a2a2;
}
</style>